import { QUERY_KEYS } from '@/config/QueryKeys';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useUserLocation } from './useUserLocation';
import { disableVehicleAlarm, enableVehicleAlarm, getVehiclesNearby } from '@badgermoleV2/vehicles';
import { MUTATION_KEYS } from '@/config/MutationKeys';

export const useUmparken = () => {
  const { location, updateCurrentPosition, locationPermission } = useUserLocation('current');

  const { data = [], isFetching } = useQuery({
    queryKey: [QUERY_KEYS.vehicle.nearby, location],
    queryFn: async ({ signal }) =>
      await getVehiclesNearby(location!.coords.latitude, location!.coords.longitude, { signal }),
    enabled: location !== undefined && locationPermission
  });

  const { mutate: enableAlarm } = useMutation({
    mutationKey: [MUTATION_KEYS.alarm.enable],
    mutationFn: async (vin: string) => await enableVehicleAlarm(vin)
  });

  const { mutate: disableAlarm } = useMutation({
    mutationKey: [MUTATION_KEYS.alarm.disable],
    mutationFn: async (vin: string) => await disableVehicleAlarm(vin)
  });

  return {
    vehicles: data,
    isFetching,
    enableAlarm,
    disableAlarm,
    updateCurrentPosition,
    locationPermission
  };
};
